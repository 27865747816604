<template>
  <div class="main-body">
    <div class="card-item">
      <div class="order-attention">
        <p>微信公众号设置</p>
      </div>
      <a-divider />
      <div class="action-body">
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/config?appid=')">
            <WechatOutlined class="action-icon" />
            <span>
              <div class="title">公众号管理</div>
              <div class="desc">设置参数</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/material?appid=')">
            <MessageOutlined class="action-icon" />
            <span>
              <div class="title">消息素材</div>
              <div class="desc">添加消息素材</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/fans?appid=')">
            <UsergroupAddOutlined class="action-icon" />
            <span>
              <div class="title">粉丝列表</div>
              <div class="desc">粉丝管理</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/menu?appid=')">
            <MenuUnfoldOutlined class="action-icon" />
            <span>
              <div class="title">自定义菜单</div>
              <div class="desc">底部菜单管理</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/share?appid=')">
            <ShareAltOutlined class="action-icon" />
            <span>
              <div class="title">分享设置</div>
              <div class="desc">内容自定义</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/replay?appid=')">
            <FireOutlined class="action-icon" />
            <span>
              <div class="title">回复设置</div>
              <div class="desc">消息触发回复</div>
            </span>
          </div>
        </div>
        <div class="action-item">
          <div class="action-item-btn" @click="goTo('/client/wechat/message?appid=')">
            <ReadOutlined class="action-icon" />
            <span>
              <div class="title">模板消息</div>
              <div class="desc">模板消息设置</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>微信公众号使用流程</p>
      </div>
      <a-divider />
      <a-steps :current="5" size="small">
        <a-step title="注册微信公众号" />
        <a-step title="配置微信链接" />
        <a-step title="配置相关域名" />
        <a-step title="配置微信密钥" />
        <a-step title="配置微信模板消息" />
      </a-steps>
      <div class="weixin-url">
        <span>微信公众平台地址：</span>
        <a href="https://mp.weixin.qq.com" target="_blank">https://mp.weixin.qq.com</a>
      </div>
      <div class="weixin-url">
        <span>微信开放平台地址：</span>
        <a href="https://open.weixin.qq.com" target="_blank">https://open.weixin.qq.com</a>
      </div>
      <div class="weixin-url">
        <span>微信商户平台地址：</span>
        <a href="https://pay.weixin.qq.com" target="_blank">https://pay.weixin.qq.com</a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  WechatOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
  MenuUnfoldOutlined,
  ShareAltOutlined,
  FireOutlined,
  ReadOutlined,
} from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: {
    WechatOutlined,
    MessageOutlined,
    UsergroupAddOutlined,
    MenuUnfoldOutlined,
    ShareAltOutlined,
    FireOutlined,
    ReadOutlined,
  },
  setup() {
    const route = useRoute();
    const appid = route.query.appid;
    const router = useRouter();
    const goTo = (url) => {
      router.push(url + appid);
    };
    return {
      goTo,
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;

  .action-body {
    display: flex;
    flex-wrap: wrap;

    .action-item {
      width: 20%;

      .action-item-btn {
        display: flex;
        background-color: #f0f2f5;
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;

        .action-icon {
          font-size: 36px;
          margin: 6px 10px;
          color: #52c41a;
        }

        .title {
          font-size: 14px;
        }

        .desc {
          color: #999999;
        }
      }
    }
  }

  .weixin-url {
    margin: 20px 0;
  }
}
</style>